


















import { Vue, Component } from 'vue-property-decorator';

import CallToAction from '../../components/6-other/call-to-action.vue';

@Component({
  components: { CallToAction },
})
export default class FAQ extends Vue {
  public get items() {
    return Array(20)
      .fill(null)
      .map((_, index) => {
        const i = index + 1;
        return {
          active: this.$t(`faq-${i}-active`),
          content: this.$t(`faq-${i}-content`),
          title: this.$t(`faq-${i}-title`),
        };
      })
      .filter(i => i.active);
  }
}
